<template>
    <b-card>
        <b-row>
            <b-col cols="9">
                <b-button class="m-1 " v-ripple.400="'#1396EB'" variant="primary" v-b-modal.modal-primaryadd>
                    Добавление
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-form-select class="m-1" v-model="defaultLang" :options="langs" text-field="name" value-field="code"
                    @change="getTeam" />
            </b-col>
        </b-row>
        <b-modal @ok="add" @hidden="resetModal" id="modal-primaryadd" ok-only ok-title="Добавить"
            modal-class="modal-primary" centered title="Добавление">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="ФИО" label-for="Title">
                        <b-form-input v-model="team.title" id="Title" placeholder="ФИО" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Специальность" label-for="speciality">
                        <b-form-input id="speciality" v-model="team.speciality" placeholder="Специальность" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Изображение:</label>
                        <b-form-file v-model="team.file" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание</label>
                        <b-form-textarea v-model="team.descriptions" id="textarea-default"
                            placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Язык" label-for="langs">
                    <b-form-select v-model="team.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
        <b-table :fields="tableColumns" responsive="sm" :items="teams">
            <template #cell(img)="data">
                <b-avatar v-if="data.item.img" class="mr-1" :src="data.item.img" />
            </template>
            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-button class="btn-icon mr-1" variant="gradient-primary" id="gradient-primaryy"
                        @click="edit(data.item)" v-b-modal.modal-primaryedit>
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button class="btn-icon" variant="gradient-danger" @click="confirmDelete(data.item)">
                        <feather-icon icon="TrashIcon" />
                    </b-button>
                </div>
            </template>
        </b-table>
        <b-modal @ok="handleEdit" @hidden="resetModal" id="modal-primaryedit" ok-only ok-title="Редактировать"
            modal-class="modal-primary" centered title="Редактирование">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="ФИО" label-for="Title">
                        <b-form-input v-model="team.title" id="Title" placeholder="ФИО" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Специальность" label-for="speciality">
                        <b-form-input id="speciality" v-model="team.speciality" placeholder="Специальность" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Изображение:</label>
                        <b-form-file v-model="team.file" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание</label>
                        <b-form-textarea v-model="team.descriptions" id="textarea-default"
                            placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Язык" label-for="langs">
                    <b-form-select v-model="team.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </b-card>
</template>
  
<script>
import { BAvatar, BFormSelect, BFormFile, BFormTextarea, BFormInput, BRow, BCol, BFormGroup, BModal, VBModal, BButton, BCard, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BAvatar,
        BFormSelect,
        BFormFile,
        BFormTextarea,
        BFormInput,
        BRow,
        BCol,
        BFormGroup,
        BModal,
        VBModal,
        BCard,
        BButton,
        BTable,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {

        return {
            defaultLang: 'ru',
            teams: [],
            langs: [],
            team: {
                title: '',
                file: '',
                descriptions: "",
                speciality: "",
                lang: '',
                file: []
            },
            tableColumns: [
                {
                    key: 'id',
                    label: '#',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Фио',
                    sortable: true,
                },
                // {
                //     key: 'speciality',
                //     label: 'Специальность',
                //     sortable: true,
                // },
                {
                    key: 'img',
                    label: 'Изображение',
                    sortable: true,
                },
                {
                    key: 'description',
                    label: 'Описание',
                    sortable: true,
                },

                {
                    key: 'actions',
                    label: 'Действия',
                }]
        }
    },
    mounted() {
        this.getTeam()
        this.getLangs()
    },
    methods: {
        getLangs() {
            axios.get(`https://back.khojaobigarm.tj/api/langs`)
                .then(res => {
                    this.langs = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },

        getTeam() {
            const getTeam = {
                method: 'get',
                url: 'https://back.khojaobigarm.tj/api/team',
                headers: {
                    'Accept-Language': this.defaultLang
                }
            };
            axios(getTeam)
                .then(res => {
                    this.teams = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },
        async add() {
            const myFormData = new FormData()
            myFormData.append('name', this.team.title)
            myFormData.append('img', this.team.file)
            myFormData.append('description', this.team.descriptions)
            myFormData.append('lang_id', this.team.lang)
            myFormData.append('speciality', this.team.speciality)


            await axios({
                method: 'post',
                url: `https://back.khojaobigarm.tj/api/team`,
                data: myFormData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(() => {
                    this.addStatus = true
                    this.getTeam()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Успешно',
                            text: 'Запись успешно добавлен',
                            icon: 'CheckSquareIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(e => {
                    this.addStatus = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Ошибка',
                            text: e.message,
                            icon: 'CheckSquareIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
        resetModal() {
            this.team.title = ''
            this.team.id = ''
            this.team.lang = ''
            this.team.file = ''
            this.team.descriptions = ''
            this.team.speciality = ''
        },
        async handleEdit() {
            const myFormData = new FormData()
            myFormData.append('name', this.team.title)
            myFormData.append('img', this.team.file)
            myFormData.append('description', this.team.descriptions)
            myFormData.append('lang_id', this.team.lang)
            myFormData.append('speciality', this.team.speciality)
            myFormData.append('_method', 'PUT')
            if (this.team.id !== '') {
                await axios({
                    method: 'post',
                    url: `https://back.khojaobigarm.tj/api/team/${this.team.id}`,
                    data: myFormData,
                    config: {},
                })
                    .then(() => {
                        this.getTeam()
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Успешно',
                                text: 'Данные были изменены',
                                icon: 'CheckSquareIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ошибка',
                                text: e.message,
                                icon: 'CheckSquareIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        },
        edit(data) {
            this.team.title = data.name
            this.team.id = data.id
            this.team.lang = data.lang.id
            this.team.file = data.file
            this.team.descriptions = data.description
            this.team.speciality = data.speciality
        },
        confirmDelete(data) {
            this.$bvModal
                .msgBoxConfirm('После удаления данные восстановить нельзя будет!', {
                    title: 'Вы уверены что хотите удалить?',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отменить',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        axios.delete(`https://back.khojaobigarm.tj/api/team/${data.id}`)
                            .then(() => {
                                this.getTeam()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Успешно',
                                        text: 'Данные были удалены',
                                        icon: 'CheckSquareIcon',
                                        variant: 'success',
                                    },
                                })
                            })
                            .catch(e => {
                                console.log(e)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Ошибка',
                                        text: e.message,
                                        icon: 'CheckSquareIcon',
                                        variant: 'dander',
                                    },
                                })
                            })
                    }
                })
        },
    }
}
</script>